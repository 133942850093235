$mobile-width: 768px;

/* You can add global styles to this file, and also import other style files */
html,
body {
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  scroll-behavior: smooth;
}

body {
  background-color: black;
  background-image: url("/assets/img/textured-background.jpg");
  background-attachment: fixed;
  color: white;
  background-size: cover;
  overflow-x: hidden;
}

.error-message {
  margin-top: 10px;
  padding-left: 200px;
  @media (max-width: $mobile-width) {
    padding-left: 0;
  }
}

input,
select {
  font-family: "Raleway", sans-serif !important;
  letter-spacing: 1px;
  padding: 6px 10px;
}

select {
  font-size: 20px;
}

.error {
  border-color: red;
}

a {
  color: #ff6900 !important;
}

.mc-status:focus {
  outline: none;
}

@keyframes rattle {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5px, 0);
  }
  20% {
    transform: translate(0, -5px);
  }
  30% {
    transform: translate(5px, 0);
  }
  40% {
    transform: translate(0, 5px);
  }
  50% {
    transform: translate(-2px, 0);
  }
  60% {
    transform: translate(0, -2px);
  }
  70% {
    transform: translate(2px, 0);
  }
  80% {
    transform: translate(0, 2px);
  }
  90% {
    transform: translate(-1px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes twist {
  0% {
    transform: scale(1) rotate(3deg);
  }
  50% {
    transform: scale(0.9) rotate(-3deg);
  }
  100% {
    transform: scale(1) rotate(3deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slam {
  0% {
    transform: scale(10, 10);
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
